<template>
    <dialogBox>
        <template v-slot:title>{{$t('ResetAccountNumbers')}}</template>
        <template v-slot:body>
            <div class="alert alert-danger">
                <p class="mb-0">
                    <span class="fw-semibold">{{ $t('warning') }} :</span>
                    {{ $t('resetNumInfo') }}
                </p>
            </div>
            <b-row>
                <template v-for="item in internalSuppliers.providers" :key="item">
                    <b-col lg="12" v-if="item.providerTitle">
                        <label class="form-label mb-2">{{ $t('EnterLatestAccountNumber') }}</label> | <span class="text-muted">{{ item.providerTitle }}</span>
                        <div class="mb-2">
                            <input class="form-control" type="number" v-model="item.num" oninput="this.value = this.value.replace(/\./g, '')" />
                        </div>
                    </b-col>
                </template>
            </b-row>
        </template>
        <template v-slot:footer-bottom>
            <button class="btn link-danger fw-medium" @click="this.$emit('close')"><i class="ri-close-line me-1 align-middle"></i> {{ $t('cancel') }} </button>
            <button 
                type="button" 
                class="btn btn-success" 
                @click="toReset(internalSuppliers.providers)"
            >
                {{ $t('Reset') }}
            </button>
        </template>
    </dialogBox>
</template>

<script>
import dialogBox from '@/components/modal/dialog'
import { Invoices } from '@/API'
import { storeS } from '@/store';

let apiServe = new Invoices();

export default{
    components: {
        dialogBox
    },
    data(){
        return{
            form: {
                numFOP: '',
                numTOV: ''
            },
            internalSuppliers: {
                providers: []
            }
        }
    },
    created(){
        // внутрішні постачальники
        this.providers.forEach(element => {
            if(element.providerType == "internal" && element.providerTitle){
                var obj = {
                    providerId: element.providerId, 
                    providerTitle: element.providerTitle,
                    num: ''
                }
                this.internalSuppliers.providers.push(obj)
            }
        });
    },
    methods: {
        toReset(e){
            const requestData = {
                providers: this.internalSuppliers.providers.map((p) => ({ providerId: p.providerId, num: p.num })),
            };
            apiServe.resettingNumbersByProviders(requestData).then(result => {
                if(result.status == 'done'){
                    this.$toast.success('done')
                    this.$emit('close')
                } else {
                    this.$toast.error(this.$t('err'))
                }
            })
        }
    },
    computed: {
        providers() {
            return storeS.providers
        }
    }
}
</script>