<template>
      <PageHeader :title="title"/>
      <div class="col-sm-auto" style="margin-bottom:15px;">
        <button @click="infoblockShow = !infoblockShow" type="button" :class="`btn btn-${infoblockShow == true ? 'primary' : 'info'} waves-effect waves-light`" style="margin-right: 10px;"><i :class="`${infoblockShow == true ? 'bx bxs-hide' : 'bx bxs-info-circle'}`"></i></button>
        <button v-if="perms[2000]" @click="showReset = true" type="button" :class="`btn btn-secondary waves-effect waves-light`"><i :class="`ri-restart-line`"></i></button>
      </div>

      <infoblocks v-if="infoblockShow" :obj="this.information" style="margin-top:15px;" />
    
      <!-- элемент таблицы -->
      <tablecustom 
        @search="searchB"
        :dataParams="dataParams"
        :objParams="objParams"
        :columns="columns" 
        :rows="rows" 
        :pages="objPages"
        :showpag="true"
        :createdform="this.perms['1701'] || this.perms['2000']"
        @create="modalCreate = true" 
        @changelimit="changelimit"
        @changeFilter="changeFilter" 
        @clearFilter="clearFilter" 
        @open="open" 
        @remove="remove" 
        @duplicate="duplicate" 
        @add = 'add(e)'
        @getdata="getdata"/>
        

      <template v-for="forms in InvForms" >
        <!-- карточка счёта -->
          <viewbox @edit="edit" @close="InvForms--" :obj="obj" @remove="remove" :ifPrevopen="ifPrevopen" :ifNextopen="ifNextopen" @prevOpen="prevOpen" @nextOpen="nextOpen" @getdata="this.getdata()"></viewbox>
      </template>
      <!-- создать счёт -->
      <createBox v-if="modalCreate" @close="closeCreate" @refresh = "getdata()" @save="save" :obj="this.form" :perm="this.perm" @open="open" @closeLast="this.InvForms--"></createBox>


      <resetNumProviders 
        v-if="showReset"
        @close="showReset = false;getdata()"
      />


  </template>
  
  <script>
  import PageHeader from "@/components/page-header";
  import tablecustom from '@/components/globaltable/index';
  import infoblocks from '@/components/info/blocks'
  import { Invoices } from '@/API.js';
  import createBox from './createinvoices.vue'
  import viewbox from './view.vue'
  // import resetBox from './resetNum'
  import resetNumProviders from './resetNumProviders.vue'
  import Multiselect from "@vueform/multiselect";
  import "@vueform/multiselect/themes/default.css";
  import { storeS } from "../../store";
  import { mutateStatusInv, mutateProviderInv } from '@/usabilityScripts/globalMutate.js'
  
  let apiServe = new Invoices();
//   let apiArchive = new Archive();
  
  export default {
    components: {
      PageHeader,
      tablecustom,
      viewbox,
      createBox,
      Multiselect,
      infoblocks,
      resetNumProviders
    },
    data() {
      return {
        form: '',
        perm: '',
        showReset: false,
        title: this.$t('invoices'),
        modalCreate: false,
        infoblockShow: false,
        InvForms: 0,
        obj: {},
        ifNextopen: "",
        ifPrevopen: "",
        showpag: false,
        objPages: {},
        dataParams: {
            status: true,
            page: "invoices"
        },
        objParams:{
          page: '1',
          pagelimit: '10',
          search: '',
          workerId: '',
          status: '',
          provider: '',
          pr1: '',
          pr2: ''
        },
        columns: [
          {
            name: "ID",
            text: "documentId",
            align: "left",
            status: false
          },
          {
            name: this.$t('invoic'),
            text: "documentId",
            align: "left",
            status: true,
            mutate: (item, obj) => 
              {
                if(obj.date >= "2023-11-28"){
                  return "#" + obj.num.id
                } else {
                  return obj.provider == 'TOV' ? "#" + obj.numTOV.id : "#" + obj.numFOP.id
                }
              }
              // obj.provider == 'TOV' ? "#" + obj.numTOV.id : "#" + obj.numFOP.id
          },
          {
            name: this.$t("Provider"),
            text: "provider",
            align: "left",
            status: true,
            mutate: (item) => this.mutateProvider(item)
          },
          {
            name: this.$t("created"),
            text: "workerName",
            align: "left",
            status: false,
          },
          {
            name: this.$t("client"),
            text: "sellerPhone",
            align: "left",
            status: true,
          },
          {
            name: this.$t("date"),
            text: "datetime",
            align: "left",
            status: true,
          },
          {
            name: this.$t("Sum"),
            text: "sum",
            align: "left",
            status: true,
            mutate: (item) => item.toFixed(2)
          },
          {
            name: this.$t("Status"),
            text: "payment",
            align: "right",
            status: true,
            mutate: (item) => this.mutateStatus(item.status).name,
            mutateClass: (item) => this.mutateStatus(item.status).color
          }
        ],
        rows: [],
        information: [
          {
              title: this.$t('Total'),
              value: "0",
              icon: "las la-file-invoice",
              color: "info",
              sum: "0"
          },
          {
              title: this.$t('Paid'),
              value: "0",
              icon: "las la-file-invoice",
              color: "success"
          },
          {
              title: this.$t('awaitingPayment'),
              value: "0",
              icon: "las la-file-invoice",
              color: "warning"
          },
          {
              title: this.$t('Canceled'),
              value: "0",
              icon: "las la-file-invoice",
              color: "danger"
          }
        ]
      };
    },
    created() {
      this.getdata();
    },
    methods: {
      closeCreate(){
        this.perm = false;
        this.modalCreate = false;
      },
      edit(e) {
        this.InvForms--
        this.perm = true;
        this.form = e;
        this.modalCreate = true; //идём эдитить
      },
      getdata(page){
        this.objParams.page = page != undefined ? page : '1';
        apiServe.getAllInv(this.objParams).then(result => {
          if(result.status === "done") {
            this.rows = result.data.products;
            this.objPages = result.data;

            this.information[0].value = result.data.total;
            this.information[1].value = result.data.countPaid;
            this.information[2].value = result.data.countPendingPay;
            this.information[3].value = result.data.countAnnual;

            this.information[0].sum = result.data.totalSum;
            this.information[1].sum = result.data.sumPaid;
            this.information[2].sum = result.data.sumPendingPay;
            this.information[3].sum = result.data.sumAnnul;
          } else {
            this.$toast.error(this.$t('error') + ` #1700`);
          }
        })
      },
      arraySum(array){
        var sum = 0;
        for(var i = 0; i < array.length; i++){
          sum += Number(array[i].sum);
        }
        return sum
      },
      open: function(e){
          var id = e.documentId ? e.documentId : e;
          this.ifPrevopen = id == this.rows[0].documentId ? false : true;
          this.ifNextopen = id == this.rows[this.rows.length-1].documentId ? false : true;
          apiServe.getInv(id).then(result => {
            if(result.status === 'done'){
            this.obj = result.data;
            this.InvForms++;
          }
        })
      },
      prevOpen(e){
        var current_id = e;
        var prev_id = ""; 

        for(var value in this.rows){
          if(this.rows[value].documentId == current_id){
            prev_id = this.rows[Number(value)-Number('1')].documentId;
          }
        }
        this.open(prev_id);
      },

      add() {
        this.modalCreate = true;
      },

      nextOpen(e){
        var current_id = e;
        var next_id = "";

        for(var value in this.rows){
          if(this.rows[value].documentId == current_id){
            next_id = this.rows[Number(value)+Number('1')].documentId;
          }
        }
        this.open(next_id);
      },
      changeFilter(name, value){
        if(!name.type && value != undefined){
          if(name == 'pr'){
            this.objParams['pr1'] = value[0];
            this.objParams['pr2'] = value[1];
          } else {
            this.objParams[name] = value;
          }
          this.getdata();
        }
        
      },
      searchB(e){
        this.objParams.search = e
        this.getdata();
      },
      changelimit: function(limit){
        this.objParams.pagelimit = limit;
        this.getdata();
      },
      remove: function(e, form){
        apiServe.deleteInv(form.documentId).then(res => {
          if(res.status === 'done'){
            this.InvForms--;
            this.$toast.success(this.$t('Removed'));
            this.getdata();
          }
        })
      },
      mutateStatus(e){
        return mutateStatusInv(e)
      },
      mutateProvider(e){
        return mutateProviderInv(e)
      },
    },
    computed: {
      perms(){
        return storeS.perms
      }
    },
    mounted() {
      this.eventBus.on('saveINV', (status) => {
        if(status == true) {
          this.getdata();
        }
      }),
      this.eventBus.on('editINV', (status) => {
        if(status == true) {
          this.getdata();
        }
      })
    }
  };
  </script>
  
  <style scoped>
  .tr_etner:hover {
    cursor: pointer;
    background: #f3f3f9;
  }
  </style>
  